<template>
  <div class="rows" style="padding: 0">
    <div class="cols videowall-content">
      <div class="videowall-settings flex-cell--static">
        <el-collapse v-model="activeName">
          <el-collapse-item :title="$tf(['common.layout'])" name="1">
            <div class="layout-settings-content">
              <div class="layout-buttons-wrapper">
                <div class="cols--center">
                  <div
                    v-for="button in buttons.slice(0, 4)"
                    class="button-wrapper"
                    :class="{ 'button-wrapper--active': isLayoutActive(button) }"
                    @click="switchLayout(button)"
                  >
                    <i class="layout-button" :class="'button-q' + button.layout"></i>
                  </div>
                </div>
                <div class="cols--center">
                  <div
                    v-for="button in buttons.slice(4, 8)"
                    class="button-wrapper"
                    :class="{ 'button-wrapper--active': isLayoutActive(button) }"
                    @click="switchLayout(button)"
                  >
                    <i class="layout-button" :class="'button-qe-' + button.layout"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item :title="$tf(['common.cameras'])" name="2" class="video-wall-cameras">
            <el-tree
              :data="treeData"
              :props="defaultProps"
              @node-drag-start="handleDragStart"
              draggable
              default-expand-all
              :allow-drag="allowDrag"
              :allow-drop="allowDrop"
            >
            </el-tree>
          </el-collapse-item>
          <el-collapse-item :title="$tf(['common.filters'])" name="3" v-if="state.episodes">
            <el-form class="video-wall-filters" label-position="top">
              <el-form-item :label="$tfo('common.watch_lists')">
                <el-select
                  name="matched-lists"
                  v-model="filter.matched_lists"
                  multiple
                  @change="clearOnChange('matched_lists')"
                  :placeholder="$tf('common.not_selected')"
                  :multiple-limit="10"
                >
                  <el-option
                    v-for="listItem in $store.state.watch_lists.items"
                    :key="'watchlist-' + listItem.id"
                    :value="listItem.id"
                    :label="$filters.shortString(listItem.name)"
                  >
                    <watch-list-option :item="listItem"></watch-list-option>
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="$tf('matches,, 1')" v-if="tab === 'car'">
                <el-select name="no_car_match" v-model="filter.no_car_match" placeholder="">
                  <el-option value="" :label="$tf('common.all')"></el-option>
                  <el-option value="True" :label="$tfo('common.only_without_matches')"></el-option>
                  <el-option value="False" :label="$tfo('common.only_with_matches')"></el-option>
                </el-select>
              </el-form-item>

              <template v-else>
                <el-form-item :label="$tf('face_matches')">
                  <el-select name="no_face_match" v-model="filter.no_face_match" placeholder="">
                    <el-option value="" :label="$tf('common.all')"></el-option>
                    <el-option value="True" :label="$tfo('common.only_without_matches')"></el-option>
                    <el-option value="False" :label="$tfo('common.only_with_matches')"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$tf('body_matches')">
                  <el-select name="no_body_match" v-model="filter.no_body_match" placeholder="">
                    <el-option value="" :label="$tf('common.all')"></el-option>
                    <el-option value="True" :label="$tfo('common.only_without_matches')"></el-option>
                    <el-option value="False" :label="$tfo('common.only_with_matches')"></el-option>
                  </el-select>
                </el-form-item>
              </template>

              <el-form-item :label="$tf('common.acknowledged')">
                <el-select name="acknowledged" v-model="filter.acknowledged" @change="clearOnChange('acknowledged')" placeholder="">
                  <el-option value="" :label="$tf('common.all')"></el-option>
                  <el-option value="False" :label="$tfo('common.only_unacknowledged')"></el-option>
                  <el-option value="True" :label="$tfo('common.only_acknowledged')"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
              </el-form-item>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="rows stretched">
        <div class="content-header cols videowall-header">
          <h2>
            {{ $tf('video_wall') }}
          </h2>
          <div class="fullscreen-button" @click="toggleFullscreen">
            <span>{{ $tf(isFullscreen ? 'exit_fullscreen' : 'enter_fullscreen') }}</span>
            <span class="icon-fullscreen"></span>
          </div>
        </div>
        <div class="cols videowall-wrapper">
          <!-- Single -->
          <div class="videowall-block cols" v-if="state.layout === 1">
            <videowall-item id="cam1" class="flex-cell--1"></videowall-item>
          </div>
          <!-- 2 screens -->
          <div class="videowall-block" v-if="state.layout === 2">
            <div class="videowall-rows-wrapper">
              <videowall-item id="cam1"></videowall-item>
              <videowall-item id="cam2"></videowall-item>
            </div>
          </div>
          <!-- 3 screens -->
          <div class="videowall-block" v-if="state.layout === 3">
            <div class="videowall-rows-wrapper">
              <videowall-item id="cam1"></videowall-item>
              <videowall-item id="cam2"></videowall-item>
              <videowall-item id="cam3"></videowall-item>
            </div>
          </div>
          <!-- 4 screens -->
          <div class="videowall-block" v-if="state.layout === 4">
            <div class="videowall-medium-wrapper">
              <videowall-item id="cam1"></videowall-item>
              <videowall-item id="cam2"></videowall-item>
              <videowall-item id="cam3"></videowall-item>
              <videowall-item id="cam4"></videowall-item>
            </div>
          </div>
          <!-- 6 screens -->
          <div class="videowall-block cols flex-multi" v-if="state.layout === 6">
            <videowall-item id="cam1" class="flex-cell--2-3"></videowall-item>
            <div class="videowall-small-wrapper flex-cell--1-3">
              <videowall-item class="flex-cell--1" id="cam2"></videowall-item>
              <videowall-item class="flex-cell--1" id="cam3"></videowall-item>
            </div>
            <videowall-item class="flex-cell--1-3" id="cam4"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam5"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam6"></videowall-item>
          </div>
          <!-- 9 screens -->
          <div class="videowall-block cols flex-multi" v-if="state.layout === 9">
            <videowall-item class="flex-cell--1-3" id="cam1"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam2"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam3"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam4"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam5"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam6"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam7"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam8"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam9"></videowall-item>
          </div>

          <div v-if="state.episodes" class="episodes-container">
            <div class="episodes-filter">
              <common-tabs v-model="tab" :items="episodesTabs" />
            </div>
            <div class="episodes flex-cell--1-2 height-100" style="overflow: auto" v-if="state.episodes && cameras.length" v-loading="episodesModule.loading">
              <div v-if="episodesItems.length">
                <episode-item :key="'episode-' + item.id" :item="item" :type="`${tab}s`" v-for="item in episodesItems" />
              </div>
              <div v-else class="cols--center">
                <div>{{ $tf('no_data') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideowallItem from './videowall-item.vue';
import EpisodeItem from '../episodes/item';
import _ from '@/apps/common/lodash';
import WatchListOption from '../watch-lists/option.vue';
import { EpisodeType, EpisodeTypeList } from '../../store/episodes';
import { carWallEpisodesModule, humanWallEpisodesModule } from '../../store';

export default {
  name: 'video-wall',
  components: {
    VideowallItem,
    EpisodeItem,
    WatchListOption
  },
  data() {
    return {
      activeName: ['1', '2', '3'],
      treeData: [],
      isFullscreen: false,
      tab: EpisodeType.Human,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      buttons: [
        {
          layout: 1,
          episodes: false
        },
        {
          layout: 4,
          episodes: false
        },
        {
          layout: 6,
          episodes: false
        },
        {
          layout: 9,
          episodes: false
        },
        {
          layout: 1,
          episodes: true
        },
        {
          layout: 2,
          episodes: true
        },
        {
          layout: 3,
          episodes: true
        },
        {
          layout: 4,
          episodes: true
        }
      ]
    };
  },
  created() {
    this.loadItems();
  },
  watch: {
    cameras: function () {
      this.applyFilterAndLoadEpisodes();
    },
    filter: {
      handler: function () {
        this.loadEpisodes();
      },
      deep: true
    },
    'state.episodes': function (v, p) {
      this.applyFilterAndLoadEpisodes();
      window.dispatchEvent(new Event('resize'));
    },
    '$store.state.faces_events.commandItems': function (v, p) {
      if (!this.state.episodes) return;
      this.applyFilterAndLoadEpisodes();
      this.$notify({ message: this.$tf('events | were | acknowledged,,1'), position: 'bottom-right' });
    }
  },
  mounted() {
    this.tab = this.episodesTabs[0].name;

    if (localStorage.layout) {
      this.state.layout = parseInt(localStorage.layout);
      this.state.episodes = localStorage.episodes === 'true';
    }

    let camera_groups = this.$store.dispatch(this.$store.state.camera_groups.Action.Get),
      cameras = this.$store.dispatch(this.$store.state.cameras.Action.GetAll);
    Promise.all([camera_groups, cameras]).then(() => {
      this.createCamerasTree();
    });
    this.$store.state.app.fileHandler = false;
    window && window.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
  },
  computed: {
    state() {
      return this.$store.state.video_wall;
    },
    episodesModule() {
      return this.tab === 'car' ? carWallEpisodesModule : humanWallEpisodesModule;
    },
    episodesItems() {
      return this.episodesModule.items.filter((item) => item.cameras.some((cameraId) => this.cameras.includes(cameraId)));
    },
    cameras() {
      return Object.keys(this.state.items).map((v) => this.state.items[v] && this.state.items[v].id).filter((v) => !!v);
    },
    filter() {
      return this.episodesModule.filter.current;
    },
    hasFilters() {
      const difference = _.differenceOf(this.filter, this.episodesModule.filter.empty),
        result = Object.keys(difference).filter((v) => v !== 'cameras').length;
      return !!result;
    },
    enabledObjects() {
      return this.$store.getters.enabledObjects;
    },
    tabs() {
      return EpisodeTypeList.map((i) => ({ name: i, i18n: `${i}_episodes` }));
    },
    currentFilter() {
      return this.$store.state[`episodes/${this.tab}s`].filter;
    },
    episodesTabs() {
      return this.tabs.filter(({ name }) => {
        if (name === 'human') {
          return this.enabledObjects.includes('bodies') || this.enabledObjects.includes('faces');
        } else if (name === 'car') {
          return this.enabledObjects.includes('cars');
        } else {
          return [];
        }
      });
    }
  },
  methods: {
    loadEpisodes() {
      if (this.cameras.length) {
        this.episodesModule.get();
      } else {
        this.episodesModule.items = [];
      }
    },
    isLayoutActive(item) {
      return this.state.layout === item.layout && this.state.episodes === item.episodes;
    },
    applyFilterAndLoadEpisodes() {
      if (!this.state.episodes || _.isEqual(this.cameras, this.filter.cameras)) return;
      this.filter.cameras = this.cameras;
      this.loadEpisodes();
    },
    loadItems() {
      if (localStorage.getItem('items')) {
        try {
          this.$store.state.video_wall.items = JSON.parse(localStorage.getItem('items'));
        } catch (e) {
          console.warn(e);
        }
      }
    },
    fullScreenChangeHandler(ev) {
      if (document.fullscreenElement === null) {
        this.isFullscreen = false;
      }
    },
    createCamerasTree() {
      let newCameraGroups = this.$store.getters.cameraGroupsWithoutSpecial.map((item) => {
        item.label = item.name;
        return (({ id, name, label }) => ({ id, name, label }))(item);
      });
      newCameraGroups.map((item) => {
        item.children = [];
        this.$store.state.cameras.items.map((camera) => {
          if (camera.group === item.id) {
            camera.label = camera.name;
            item.children.push(camera);
          }
        });
      });
      this.treeData = newCameraGroups;
    },
    handleDragStart(node, ev) {
      this.state.draggingItem = node.data;
      ev.srcElement.firstChild.style.cursor = 'move';
    },
    allowDrag(draggingNode) {
      return draggingNode.level > 1;
    },
    allowDrop(draggingNode, dropNode, type) {
      return false;
    },
    deleteUnusedCameras() {
      const currentCamLength = this.state.layout;
      let camItemsLength = 9;
      while (camItemsLength > currentCamLength) {
        const cameraId = `cam${camItemsLength}`;
        if (this.$store.state.video_wall.items[cameraId]) {
          delete this.$store.state.video_wall.items[cameraId];
          this.cameras.pop();
        }
        camItemsLength -= 1;
      }
      localStorage.setItem('items', JSON.stringify(this.$store.state.video_wall.items));
    },
    switchLayout(item) {
      this.state.layout = item.layout;
      this.state.episodes = item.episodes;
      localStorage.layout = this.state.layout;
      localStorage.episodes = this.state.episodes;
      window.dispatchEvent(new Event('resize'));
      this.deleteUnusedCameras();
    },
    toggleFullscreen(e) {
      const el = document.documentElement;
      if (!(document.fullscreenEnabled || document.webkitFullscreenEnabled)) return;
      if (!this.isFullscreen) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) {
          el.webkitRequestFullscreen();
        }
        this.isFullscreen = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        this.isFullscreen = false;
      }
    },
    clearOnChange(v) {},
    clearFilters() {
      this.episodesModule.filter.current = Object.assign(_.cloneDeep(this.currentFilter.empty), {
        cameras: this.filter.cameras
      });
    }
  }
};
</script>

<style lang="stylus">
.videowall-content {
   .episodes-container {
     overflow: visible;
     white-space: nowrap;
     width: 100%;
     margin-left: 4rem;

    .cell--1-3 {
      flex-basis: unset;
    }

    > .episodes-filter {
      max-width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      .el-form {
        display: inline-block;
        min-width: 10rem;
        max-width: 10rem;
        padding-bottom: 1rem;
      }
    }
  }
}
</style>
