<template>
  <div class="videowall-item" @dragenter="dragEnter" @drop="dragDrop" @dragover="dragOver" @dragend="dragEnd" :style="{ cursor: cursor }">
    <div class="videowall-item--content" @dragstart="dragStart" :id="id" draggable="true">
      <div class="videowall-item-header" v-if="item">
        <el-tooltip placement="top" :content="item.state_color_desc">
          <span class="cam-status" :style="{ 'background-color': item.state_color }"></span>
        </el-tooltip>
        <span class="cam-name">{{ item.name }}</span>
      </div>
      <camera-screenshot v-if="item && state.wallType === 'screenshot'" refresh :screenshot="item.screenshot" />
      <div v-if="item && state.wallType === 'video'" class="videogroup-wrapper">
        <camera-player :id="item.id" :show-bbox-objects="showBboxObjects" :gdpr="isGDPREnabled" reconnect-on-close />
      </div>
    </div>
  </div>
</template>

<script>
import CameraScreenshot from '../cameras/camera-screenshot.vue';
import CameraPlayer from '../cameras/player.vue';
const UpdateVideoWallItemInterval = 5000;

export default {
  name: 'videowall-item',
  components: {
    CameraScreenshot,
    CameraPlayer
  },
  props: {
    id: {
      type: String
    }
  },
  created() {
    this.updateItem = this.updateItem.bind(this);
  },
  mounted() {
    this.initUpdateItemInterval();
    this.updateItem();
  },
  beforeDestroy() {
    clearInterval(this.updateItemInterval);
  },
  computed: {
    state() {
      return this.$store.state.video_wall;
    },
    items() {
      return this.$store.state.video_wall.items;
    },
    cursor() {
      return this.item === undefined || !this.state.draggable ? 'default' : 'move';
    },
    isGDPREnabled() {
      return this.$store.state.config.available_video_wall_features?.gdpr;
    },
    item: {
      get: function () {
        return this.items[this.id];
      },
      set: function (val) {
        this.$set(this.$store.state.video_wall.items, this.id, val);
      }
    },
    showBboxObjects() {
      const objects = this.$store.state.config.available_video_wall_features;
      return objects && Object.keys(objects).filter((i) => objects[i]);
    }
  },
  methods: {
    initUpdateItemInterval() {
      clearInterval(this.updateItemInterval);
      this.updateItemInterval = setInterval(this.updateItem, UpdateVideoWallItemInterval);
    },
    updateItem() {
      const id = this.item && this.item.id;
      if (!id) return;
      this.$store
        .dispatch(this.$store.state.cameras.Action.Get, { id })
        .then((v) => {
          if (this.item.id === v.id) this.item = v;
        })
        .catch((e) => {
          let deleted = e.toString().indexOf('NOT_FOUND');
          if (deleted) this.deleteItem();
        });
    },
    saveToLocalStorage(target, item) {
      localStorage.setItem(target, JSON.stringify(item));
    },
    dragStart(ev) {
      if (typeof this.item !== 'undefined') {
        this.state.draggingItem = this.id;
        ev.dataTransfer.setData('text', 'draggable_item_to_fix_firefox_dragging');
        return true;
      } else {
        ev.preventDefault();
        return false;
      }
    },
    dragEnter(ev) {
      ev.preventDefault();
      return true;
    },
    dragOver(ev) {
      ev.preventDefault();
    },
    dragDrop(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      if (typeof this.state.draggingItem === 'object') {
        this.item = this.state.draggingItem;
      } else {
        let previousItem = this.item;
        this.item = this.items[this.state.draggingItem];
        this.$set(this.$store.state.video_wall.items, this.state.draggingItem, previousItem);
      }
      this.saveToLocalStorage('items', this.items);
      return false;
    },
    dragEnd(ev) {
      ev.preventDefault();
      const x = event.clientX,
        y = event.clientY,
        { offsetLeft, offsetTop, offsetWidth, offsetHeight } = document.getElementsByClassName('videowall-block')[0],
        menu = document.getElementsByClassName('menu-content')[0].clientWidth;
      if (x < offsetLeft + menu || x > offsetLeft + menu + offsetWidth || y < offsetTop || y > offsetTop + offsetHeight) {
        this.deleteItem();
      }
    },
    deleteItem() {
      delete this.$store.state.video_wall.items[this.id];
      this.saveToLocalStorage('items', this.$store.state.video_wall.items);
      this.item = null;
    }
  }
};
</script>
